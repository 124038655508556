import ROLES from "../../../Roles.json";

export default [
  { 
    title: 'User Management',
    'href': '/users',
    icon: 'performance',
    roles: [
      ROLES.SystemAdmin,
    ]
  },
  {
    title: 'Import SAP',
    href: '/sap',
    icon: 'add',
    roles: [
      ROLES.SystemAdmin,
    ],
  },
  {
    title: 'Configuration',
    href: '/configuration',
    icon: 'cog',
    roles: [
      ROLES.SystemAdmin,
    ],
  },
  {
    title: 'Reports',
    icon: 'loadbar-doc',
    roles: [
      ROLES.SystemAdmin,
    ],
    child: [
      {
        title: 'User Access Logs',
        href: '/user-access-logs',
        icon: 'cog',
        roles: [
          ROLES.SystemAdmin,
        ],
      },
      {
        title: 'User Activation Logs',
        href: '/user-activation-logs',
        icon: 'cog',
        roles: [
          ROLES.SystemAdmin,
        ],
      },
    ]
  },
]