import ROLES from "../../../Roles.json";

export const acknowledgementsMenu = [
  {
    title: "Acknowledgements",
    href: "/acknowledgements",
    icon: "comment",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.StationManagerControlRoom,
      ROLES.CSTLeader,
      ROLES.CSTMember,
    ],
  },
];

export const opsCommMngtMenu = [
  {
    href: "/ops-comm",
    title: "CP Updates",
    icon: "clipboard",
    roles: [
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.ServiceOperationManager,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
      ROLES.OpsAdmin,
      ROLES.SystemAdmin,
    ],
  },
  {
    href: "/ops-comm/report",
    title: "CP Update Reports",
    icon: "loadbar-doc",
    roles: [
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.ServiceOperationManager,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
      ROLES.OpsAdmin,
      ROLES.SystemAdmin,
    ],
  },
  {
    href: "/ops-comm/user-report",
    title: "Officer Acknowledgement Reports",
    icon: "loadbar-doc",
    roles: [
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.ServiceOperationManager,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
      ROLES.OpsAdmin,
      ROLES.SystemAdmin,
    ],
  },
];
