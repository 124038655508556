import ROLES from "../../../Roles.json";

export default [
  { 
    title: 'Admin Configuration', 
    href: '/admin-config/', 
    icon: 'cog',
    roles: [
      ROLES.OpsAdmin,
    ],
  },
]