import ROLES from "../../../Roles.json";

export default [
  {
    href: "/fault-mgnt",
    title: "Fault Management",
    icon: "toolbox",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
    ],
  },
  {
    href: "/fault-mgnt/e-booklets",
    title: "Fault Management Guidebook",
    icon: "toolbox",
    roles: [ROLES.OpsAdmin],
  },
];
