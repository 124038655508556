import userMgntMenu from "./userManagement.menu";
import manpowerMenu from "./manpower.menu";
import cashMgntMenu from "./cashManagement.menu";
import inventoryMenu from "./inventory.menu";
import touristMenu from "./tourist.menu";
import faultManagementMenu from "./faultManagement.menu";
import shiftHandoverMenu from "./shiftHandover.menu";
// import atomsMenu from "./atoms.menu";
import stationOccurrenceMenu from "./stationOccurrence.menu";
import { acknowledgementsMenu, opsCommMngtMenu } from "./opsComm.menu";
import adminConfigMenu from "./adminConfig.menu";
import generalMenu from "./general.menu";
import ROLES from "../../../Roles.json";


export const FULL_MENU = [
  {
    title: "Popular Links",
    icon: "performance",
    child: generalMenu,
  },
  {
    title: "User Management",
    icon: "performance",
    child: userMgntMenu,
  },
  {
    title: "Manpower Management",
    href: "/manpower",
    icon: "performance",
    child: manpowerMenu,
  },
  {
    title: "Cash Management",
    href: "/cash-mgnt",
    icon: "credit-card",
    child: cashMgntMenu,
  },
  {
    title: "Inventory",
    href: "/inventory",
    icon: "stack",
    child: inventoryMenu,
  },
  {
    title: "Fault management",
    href: "/fault-mgnt",
    icon: "toolbox",
    child: faultManagementMenu,
  },
  {
    title: "Station Occurrence",
    href: "/fault-mgnt/station-occurrence",
    icon: "toolbox",
    child: stationOccurrenceMenu,
  },
  {
    title: "Acknowlegements",
    href: "/acknowledgements",
    icon: "comment",
    child: acknowledgementsMenu,
  },
  {
    title: "Ops Communication",
    href: "/ops-comm",
    icon: "clipboard",
    child: opsCommMngtMenu,
  },
  {
    title: "Wayfinding",
    href: "/wayfinding",
    icon: "organisation",
    child: touristMenu,
  },
  {
    title: "Shift Handover",
    href: "/shift-handover",
    icon: "toolbox",
    child: shiftHandoverMenu,
  },
  // {
  //   title: "ATOMS",
  //   href: "/atoms",
  //   icon: "toolbox",
  //   child: atomsMenu,
  // },
  {
    title: "Admin Configuration",
    href: "/admin-config",
    icon: "cog",
    child: adminConfigMenu,
  },
  {
    title: 'Stations',
    href: '/stations',
    icon: 'ui-kit',
    child: [
      {
        title: 'Stations',
        href: '/stations',
        icon: 'ui-kit',
        roles: [
          ROLES.SystemAdmin,
          ROLES.StationManager,
          ROLES.SeniorStationManager,
          ROLES.AssistantStationManager,
          ROLES.SeniorAssistantStationManager,
          ROLES.ServiceOperationManager,
          ROLES.OperationControlCentre,
          ROLES.ManagerStationOperation,
          ROLES.StationOperationManagement,
          ROLES.StationManagerControlRoom,
          ROLES.FSOManager,
          ROLES.FSOAdministrativeOfficer,
          ROLES.CSTLeader,
          ROLES.CSTMember,
          ROLES.OpsAdmin,
        ],
      },
    ]
  }
];

function getMenuOfRoles(role = null) {
  return FULL_MENU.map((module) => ({
    ...module,
    icon: {
      element: "asom-icon",
      attributes: {
        icon: module.icon,
      },
    },
    child: module.child
      .map((childLink) => ({
        ...childLink,
        icon: {
          element: "asom-icon",
          attributes: {
            icon: childLink.icon,
          },
        },
      }))
      .filter(({ roles = [] }) => roles.length > 0 && roles.includes(role)),
  }))
    .filter((module) => module.child.length > 0)
    .map((module) => {
      if (module.child.length === 1) {
        return module.child[0];
      }
      return module;
    });
}

export default getMenuOfRoles;
