import ROLES from "../../../Roles.json";

export default [
  {
    href: "/shift-handover",
    title: "Shift Handover",
    icon: "corner-double-up-right",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
    ],
  },
  {
    href: "/shift-handover/afc-keys",
    title: "AFC Store Spare Key Press",
    icon: "key",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
    ],
  },
  {
    href: "/shift-handover/sm-keys",
    title: "SM Keys",
    icon: "key",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
      ROLES.OpsAdmin,
    ],
  },
  {
    href: "/shift-handover/sm-duty-items",
    title: "SM Duty Items",
    icon: "display-grid",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
      ROLES.OpsAdmin,
    ],
  },
  {
    href: "/shift-handover/declaration",
    title: "Fitness Observation",
    icon: "assign",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
      ROLES.OpsAdmin,
    ],
  },
];
