import ROLES from "../../../Roles.json";

export default [
  {
    title: "Wayfinding",
    href: '/wayfinding',
    icon: "organisation",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
      ROLES.OperationControlCentre,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
      ROLES.CSTLeader,
      ROLES.CSTMember,
      ROLES.OpsAdmin,
    ],
  },
];
