import ROLES from "../../../Roles.json";

export default [
  {
    href: "/fault-mgnt/station-occurrence",
    title: "Station Occurrence",
    icon: "transcript",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
    ],
  },
  {
    href: "/fault-mgnt/station-occurrence/configurations",
    title: "Station Occurrence Configurations",
    icon: "transcript",
    roles: [ROLES.OpsAdmin],
  },
];
