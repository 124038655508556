import ROLES from "../../../Roles.json";

export default [
  {
    title: 'Roster',
    href: '/manpower/',
    icon: 'performance',
    roles: [
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
      ROLES.OperationControlCentre,
    ],
  },
  {
    title: 'Personal Roster',
    href: '/manpower/personal-roster',
    icon: 'assign',
    roles: [
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.OperationControlCentre,
      ROLES.StationManagerControlRoom,
    ],
  },
  {
    title: 'Indication for Leave',
    href: '/manpower/block_leave',
    icon: 'block',
    roles: [
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManagerControlRoom,
    ],
  },
  {
    title: 'Mutual Exchange',
    href: '/manpower/mutual_exchange',
    icon: 'arrows-exchange-v',
    roles: [
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
    ],
  },
  {
    title: "SAP Sync Reports",
    href: '/manpower/sap-sync-errors',
    icon: 'arrange-back',
    roles: [
      ROLES.SystemAdmin,
    ],
  },
  {
    title: 'Reports',
    icon: 'loadbar-doc',
    roles: [
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.StationManagerControlRoom,
      ROLES.OperationControlCentre,
    ],
    child: [
      {
        title: 'Roster Conflicts',
        href: '/manpower/conflicts',
        icon: 'arrange-back',

      },
      {
        title: 'OT Reports',
        href: '/manpower/ot-report',
        icon: 'timelapse',
      },
      {
        title: 'MOM Regulations',
        href: '/manpower/mom-regulations',
        icon: 'user-list',
      },
      {
        title: 'Leave Reports',
        href: '/manpower/leave-reports',
        icon: 'today',
      },
      {
        title: 'Pending Replacements',
        href: '/manpower/pending-replacements',
        icon: 'users',
      },
    ],
  },
  {
    title: 'Zone Management',
    href: '/manpower/zone-management',
    icon: 'ui-kit',
    roles: [
      ROLES.ServiceOperationManager,
    ],
  },
  {
    title: 'Group Management',
    href: '/manpower/group-management',
    icon: 'ui-kit',
    roles: [
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
    ],
  },
]