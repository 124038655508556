import ROLES from "../../../Roles.json";

export default [
  {
    href: "/cash-mgnt/psc",
    title: "PSC",
    icon: "dialpad",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
    ],
  },
  {
    href: "/cash-mgnt/afc",
    title: "AFC",
    icon: "view-day",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
    ],
  },
  {
    href: "/cash-mgnt/cash-declaration-form",
    title: "Cash Declaration Form",
    icon: "clipboard",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
    ],
  },
  {
    href: "/cash-mgnt/cash-order",
    title: "Cash Order",
    icon: "ereader",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
      ROLES.SystemAdmin,
    ],
  },
  {
    href: "/cash-mgnt/discrepancy-reports",
    title: "Discrepancy Reports",
    icon: "danger",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
    ],
  },

  {
    href: "/cash-mgnt/collection-summary",
    title: "Collection Summary",
    icon: "cog",
    roles: [
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
    ],
  },

  {
    href: "/cash-mgnt/configuration",
    title: "Configuration",
    icon: "cog",
    roles: [
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager,
      ROLES.SystemAdmin,
    ],
  },
];
