import ROLES from "../../../Roles.json";

export default [
  {
    title: "Reports",
    icon: "loadbar-doc",
    roles: [
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
    ],
    child: [
      {
        title: "Stock On Hand Report",
        href: "/inventory/view-items",
        icon: "abstract",
      },
      {
        title: "Delivery Report",
        href: "/inventory/reports",
        icon: "loadbar-doc",
      },
    ],
  },
  {
    title: "Inventory Types",
    icon: "list",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
    ],
    child: [
      {
        title: "General",
        href: "/inventory/general",
        icon: "chevron-right",
      },
      {
        title: "Smart Card",
        href: "/inventory/smart-card",
        icon: "chevron-right",
      },
      {
        title: "Tracked Items",
        href: "/inventory/tracked-items",
        icon: "chevron-right",
      },
      {
        title: "First Aid",
        href: "/inventory/first-aid",
        icon: "chevron-right",
      },
    ],
  },
  {
    title: "Smart Card",
    href: "/inventory/smart-card",
    icon: "math-plus",
    roles: [
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager
    ]
  },
  {
    title: "First Aid",
    href: "/inventory/first-aid",
    icon: "math-plus",
    roles: [
      ROLES.OpsAdmin,
      ROLES.ServiceOperationManager,
      ROLES.FSOManager,
      ROLES.FSOAdministrativeOfficer,
    ]
  },
  {
    href: "/inventory/order",
    title: "Order",
    icon: "transcript",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.FSOAdministrativeOfficer,
      ROLES.FSOManager
    ],
  },
  {
    href: "/inventory/configuration",
    title: "Inventory Configurations",
    icon: "globe-alt",
    roles: [
      ROLES.AssistantStationManager,
      ROLES.SeniorAssistantStationManager,
      ROLES.StationManager,
      ROLES.SeniorStationManager,
      ROLES.ServiceOperationManager,
      ROLES.ManagerStationOperation,
      ROLES.StationOperationManagement,
      ROLES.OpsAdmin,
      ROLES.FSOManager,
      ROLES.FSOAdministrativeOfficer,
    ],
  },
];
